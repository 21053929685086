import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'

// Images
import Circle from 'img/circle-arrow-dark.inline.svg'

const Section = styled.section`
  z-index: 3;
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-bottom: 2rem;

    @media (min-width: 576px) and (max-width: 1199.98px) {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: ${({ theme }) => theme.font.size.huge};
    }
  }
`

const Functionality = styled(motion.div)`
  background-color: ${({ theme }) => theme.color.dark};
  border-radius: 92px;
  color: ${({ theme }) => theme.color.light} !important;
  font-weight: ${({ theme }) => theme.font.weight.bold};

  & a {
    color: ${({ theme }) => theme.color.light} !important;
  }

  @media (min-width: 576px) {
    font-size: ${({ theme }) => theme.font.size.larger};
    line-height: ${({ theme }) => theme.font.size.larger};
  }

  @media (max-width: 575.98px) {
    font-size: ${({ theme }) => theme.font.size.bigger};
    line-height: ${({ theme }) => theme.font.size.bigger};
  }
`

const Icon = styled(Plaatjie)`
  @media (min-width: 576px) {
    height: 88px;
    width: 88px;
  }

  @media (max-width: 575.98px) {
    height: 60px;
    width: 60px;
  }
`

const SvgWrapper = styled(motion.div)`
  & svg {
    width: 38px;
    height: 38px;
  }
`

// Interface
import { FunctionalitiesProps } from 'components/flex/Functionalities/FunctionalitiesShell'

const FunctionalitiesOther: React.FC<FunctionalitiesProps> = ({
  fields,
  location,
}) => {
  const [currentIndex, setCurrentIndex] = useState(-1)

  return (
    <Section className="position-relative mb-lg-5 pb-5">
      <div className="container py-5">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="row">
              <div className="col-lg-6 col-xl-7">
                <Content content={fields.description} className="pe-lg-5" />
              </div>
              <div className="col-lg-6 col-xl-5">
                {fields.functionalities
                  ?.filter(
                    (functionality) =>
                      !location.pathname.includes(
                        functionality?.link?.title?.toLowerCase()
                      )
                  )
                  .map((functionality, index) => (
                    <Functionality
                      className="pe-4 mt-5"
                      key={index}
                      onHoverStart={() => setCurrentIndex(index)}
                      onHoverEnd={() => setCurrentIndex(-1)}
                    >
                      <NavLink
                        to={functionality?.link?.url || '#'}
                        className="d-flex justify-content-between align-items-center me-3"
                      >
                        <motion.div
                          initial={{ rotate: 0 }}
                          animate={
                            currentIndex === index
                              ? { rotate: 360 }
                              : { rotate: 0 }
                          }
                          transition={{ duration: 1 }}
                        >
                          <Icon
                            image={functionality?.icon}
                            alt=""
                            className="me-3 ms-1 my-1"
                          />
                        </motion.div>

                        <div className="d-flex align-items-center">
                          {functionality?.link?.title}
                          <SvgWrapper
                            className="ms-3"
                            initial={{ x: 0 }}
                            animate={
                              index === currentIndex ? { x: 15 } : { x: 0 }
                            }
                          >
                            <Circle />
                          </SvgWrapper>
                        </div>
                      </NavLink>
                    </Functionality>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default FunctionalitiesOther
